import fetch from 'isomorphic-fetch';

const handleErrors = (res) => {
  if (!res.ok) {
    throw Error(res.statusText);
  }
  return res;
}

export const Api = (url, options, setResponse, setError) => {
  try {
    fetch(url, options)
      .then(res => handleErrors(res))
      .then(res => res.json())
      .then(res => {
        setResponse(res);
      })
      .catch(err => {
        setError(err);
      });
  } catch (error) {
    setError(error);
  }
};
