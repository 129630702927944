import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Loading from './Loading';
import PosterInfo from './PosterInfo';
import { Media, Card, Image } from 'react-bootstrap';
import { Api } from '../services/Api';
import { priceFormatter, soldOutFormatter, framedFormatter, timeAgoFormatter } from '../utils/formatters';

const PosterOverview = (props) => {
  const [posterData, setPosterData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (props.year) {
      setLoading(true);
      setError(null);
      const uri = '/api/poster/' + props.year;
      Api(uri, {}, (res) => {
        setPosterData(res.poster);
        setLoading(false);
      }, setError);
    }
  }, [props.year]);

  if (!props.year) {
    return null;
  }

  if (loading) {
    return <Loading error={error} />;
  } else {
    return (
      <div className='poster-info'>

        <Media>
          <div className='poster-info-image'>
            <Image src={posterData.images['300']} alt={posterData.year} fluid />
          </div>
          <Media.Body>
            <PosterInfo data={posterData} />
          </Media.Body>
        </Media>
        <Card>
          <Card.Header ><h5>Market overview</h5></Card.Header>
          <Card.Body style={{ padding: 0 }}>
            <VariantsTable variants={posterData.variants} />
          </Card.Body>
        </Card>

        <Card>
          <Card.Header><h5>Market data</h5></Card.Header>
          <Card.Body style={{ padding: 0 }}>
            <MarketOverview variants={posterData.variants} />
          </Card.Body>
        </Card>
      </div>
    );
  }
};

const VariantsTable = (props) => {
  const variantColumns = [{
    dataField: 'name',
    text: 'Variant',
    sort: true,
    formatter: soldOutFormatter
  }, {
    dataField: 'price',
    text: 'Art4Now Price',
    sort: true,
    formatter: priceFormatter
  }, {
    dataField: 'market.avg',
    text: 'Market avg',
    sort: true,
    formatter: priceFormatter
  },
  {
    dataField: 'market.median',
    text: 'Market median',
    sort: true,
    formatter: priceFormatter
  },
  {
    dataField: 'market.min',
    text: 'Market min',
    sort: true,
    formatter: priceFormatter
  },
  {
    dataField: 'market.max',
    text: 'Market max',
    sort: true,
    formatter: priceFormatter
  }, {
    dataField: 'market.count',
    sort: true,
    text: 'Market #'
  }];

  const defaultSorted = [{
    dataField: 'price',
    order: 'desc'
  }];

  const variants = Object.values(props.variants).filter(
    (e) => !((e.name === 'gala' || e.name === 'artist\'s proof') && e.sales_items.length === 0)
  );
  return (
    <BootstrapTable keyField='name' data={variants} columns={variantColumns} defaultSorted={defaultSorted} bootstrap4 striped condensed hover key={0} />
  );
};

const MarketOverview = (props) => {
  const tableData = Object.values(props.variants)
    .sort((a, b) => b.price - a.price)
    .flatMap((v) => {
      return v.sales_items;
    });

  const columns = [
    {
      dataField: 'variant',
      text: 'Variant',
      sort: true
    },
    {
      dataField: 'orgTitle',
      text: 'Title',
      formatter: (cell, row) => {
        return <a href={row.url} target='_blank' rel='noopener noreferrer'>{cell}</a>;
      }
    }, {
      dataField: 'source',
      text: 'Source',
      sort: true
    }, {
      dataField: 'lastSeen',
      text: 'Seen',
      sort: true,
      formatter: timeAgoFormatter
    }, {
      dataField: 'price',
      text: 'Price',
      sort: true,
      formatter: priceFormatter
    }, {
      dataField: 'framed',
      text: 'Framed',
      sort: true,
      formatter: framedFormatter
    }
  ];
  return (
    <BootstrapTable keyField='item_id' data={tableData} columns={columns} bootstrap4 striped condensed hover key={1} />
  );
};

export default PosterOverview;
