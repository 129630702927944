import React from 'react';

import Main from './components/Main.js';
import './App.css';

function App () {
  return (
    <div className='App'>
      <Main />
    </div>
  );
}

export default App;
