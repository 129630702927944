import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Swiper from 'react-id-swiper';

const PosterMenu = (props) => {
  const [activeYear, setActiveYear] = useState();
  // Use this to get rid of missing dependency ESLint crap
  const useMountEffect = (fun) => useEffect(fun, []);

  useMountEffect(() => {
    setActiveYear(props.year);
  }, []);

  const onMenuClick = (year) => {
    setActiveYear(null);
    props.onClick(year);
  };

  const params = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    speed: 300,
    mousewheel: {
      sensitivity: 1
    },
    freeMode: true,
    loop: false,
    activeSlideKey: activeYear
  };

  if (!props.posters) {
    return null;
  }

  const posterItems = props.posters.sort((a, b) => b.year - a.year).map(
    (row, index) => {
      return (
        <div className='scroll-item' key={row.year} onClick={(e) => onMenuClick(row.year)}>
          <Image src={row.images['150']} className='menu-image' />
          <div className='caption'>{row.year}</div>
        </div>
      );
    });

  return (
    <div >
      <Swiper {...params}>
        {posterItems}
      </Swiper>
    </div>
  );
};

export default PosterMenu;
