import React from 'react';
import TimeAgo from 'react-timeago';

export const priceFormatter = (num, options = {}) => {
  const showSymbol = options.showSymbol !== false;
  const showDecimals = options.showDecimals !== false;
  if (!num) {
    return '';
  }
  return num.toLocaleString('en', {
    style: showSymbol ? 'currency' : undefined,
    currency: showSymbol ? 'USD' : undefined,
    maximumFractionDigits: showDecimals ? 2 : 0
  });
};

export const soldOutFormatter = (cell, row) => {
  if (!row.available) {
    return <p className='sold-out'>{cell} (SOLD OUT)</p>;
  }
  return <p>{cell}</p>;
};

export const framedFormatter = cell => {
  return cell ? 'Yes' : 'No';
};

export const timeAgoFormatter = cell => {
  return (<TimeAgo date={cell} />);
};
