import { useState, useCallback } from "react";
import queryString from 'query-string';

const setQueryStringWithoutPageReload = qsValue => {
  const newurl = window.location.protocol + '//' +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, '', newurl);
};

const setQueryStringValue = (
  key,
  value,
  qs = window.location.search
) => {
  const values = queryString.parse(qs);
  const newQsValue = queryString.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (
  key,
  qs = window.location.search
) => {
  const values = queryString.parse(qs);
  return values[key];
};

export const useQueryString = (key, initialValue) => {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}
