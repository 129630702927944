import React, { useState, useEffect } from 'react';
import PosterOverview from './PosterOverview';
import Loading from './Loading';
import PosterMenu from './PosterMenu';
import { Api } from '../services/Api';
import { useQueryString } from '../utils/useQueryString';

const Main = () => {
  const [posters, setPosters] = useState();
  const [error, setError] = useState();
  const [year, setYear] = useQueryString('year');

  useEffect(() => {
    const uri = '/api/posters';
    Api(uri, {}, (res) => setPosters(res.posters), setError);
  }, []);

  return (
    <div>
      <Loading response={posters} error={error} />
      <PosterMenu posters={posters} onClick={setYear} year={year} />
      <PosterOverview year={year} />
    </div>
  );
};

export default Main;
