import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

const PosterInfo = (props) => {
  const [open, setOpen] = useState(false);

  if (!props.data.info) {
    return <p className='poster-info'>No information available</p>;
  }

  const info = props.data.info;
  const descriptionParagraphs = info.description.map((d, i) => <p key={i}>{d}</p>);
  const editions = info.editions.map((e, i) => <li key={i}>{e}</li>);

  const readMoreLink = descriptionParagraphs.length > 1 ? <p onClick={() => setOpen(!open)} className='read-more-link'>{!open ? 'Read more...' : 'Read less...'}</p> : null;

  return (
    <div className='poster-info'>
      <h5>{props.data.year} poster - {info.title}</h5>
      <p className='poster-artist'>by {info.artist}</p>
      <p>Info link: <a href={props.data.url} target='_blank' rel='noopener noreferrer' >{props.data.url}</a></p>
      <div>
        <h6 className='poster-editions-header'>Editions</h6>
        <ul>
          {editions}
        </ul>
        <p>{info.originally}</p>
      </div>
      <div className='poster-description'>
        <h6 className='poster-editions-header'>Description</h6>
        <div className={open || descriptionParagraphs.length === 1 ? '' : 'poster-description-faded'}>
          {descriptionParagraphs[0]}
        </div>

        <Collapse in={open}>
          <div>
            {descriptionParagraphs.slice(1)}
          </div>
        </Collapse>
        {readMoreLink}
      </div>
    </div>
  );
};

export default PosterInfo;
