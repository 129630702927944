import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loading = (props) => {
  if (props.error) {
    return <div className='error'>Loading error: {props.error.message}</div>;
  } else if (!props.response) {
    return (
      <div className='loading'>
        <Spinner animation='border' />
      </div>
    );
  }
  return null;
};

export default Loading;
